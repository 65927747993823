<template>
  <van-pull-refresh     :style="{ 'background-image': 'url(' + bgImg + ')' }"
                        v-model="loading" class="deviceShareStatus" id="deviceShareStatus" @refresh="updateList">
    <filter-panel_-v2 ref="filter" :search-view="false" :show-date="true" :title-color="'white'" :layout="filterLayout" :options="baseOptions"
                      v-model="query" @searchEvent="searchEvent">
    </filter-panel_-v2>

    <div class="contentView">
      <el-empty class="contentMsg" v-if="tableData.length==0" description="暂无数据"></el-empty>
      <status-item-v3 :row-list="keyList" v-for="(item, index) in tableData" :data-info="item" @pushDetail="pushDetail"></status-item-v3>
    </div>
    <pagination-vue @current-change="currentChange"
                    :total-count="totalCount"
                    :page-size="pageSize"
                    :pager-count="5"
                    :current-page="page"
    ></pagination-vue>
  </van-pull-refresh>
</template>

<script>
import StatusItem from '@/views/shareAndDispatch/device/deviceShareDetail/deviceStatus/statusItem'
import ShareDetailHeader from '@/views/shareAndDispatch/device/deviceShareDetail/shareDetail/shareDetailHeader'
import StatusItemV2 from '@/views/shareAndDispatch/device/deviceShareDetail/deviceStatus/statusItemV2'
import HStack from '@/components/hStack/hStack'
import PaginationVue from '@/components/pagination/paginationVue'
import tenantBaseDataMixin from '@/components/filterPanel/tenantBaseDataMixin.js'
import StatusItemV3 from '@/views/shareAndDispatch/device/deviceShareDetail/deviceStatus/statusItemV3'
import FilterPanel_V2 from '@/components/filterPanel/filterPanel_V2'
let bgImg = require("../../../../../../static/iconImage/operationCenter.png");

export default {
  name: 'deviceShareStatus',
  components: { FilterPanel_V2, StatusItemV3, PaginationVue, HStack, StatusItemV2, ShareDetailHeader, StatusItem },
  mixins: [tenantBaseDataMixin],
  computed: {
    headerTitle() {
      if (this.$valueIsExist(this.dataQuery, 'name')) {
        return this.dataQuery['name']
      }
      return ''
    }
  },
  data() {
    return {
      bgImg:bgImg,
      query: {},
      baseOptions: {
        tenantOptions: []
      },
      otherAction: 'otherAction',
      filterLayout: [
        {
          label: '科室',
          key: 'tenantIds',
          optionKey: 'tenantOptions',
          childKey: 'children',
          valueKey: 'id',
          labelKey: 'name',
          searchKey: 'name',
          filterablePlaceholder: '搜索科室',
          defauleValue: ['']
        }
      ],
      keyList: [
        { key: 'brandName', name: '品 牌', space: '21px' },
        { key: 'modelName', name: '型 号', space: '21px' },
        { key: 'label', name: this.$VUEAPPDEVICECODE(), space: '0px' },
        { key: 'firstShareTime', name: '首次共享', space: '0px' },
        { key: 'code', name: '卡 片 号', space: '3px' },
        { key: 'status', name: '共享状态', space: '3px' },
        { key: 'categoryName', name: '设备分类', space: '0px' },
        { key: 'loanCount', name: '共享次数', space: '21px' }
      ],
      deviceIdKey: 'id',
      count: 0,
      dataQuery: {},
      page: 1,
      pageSize: 50,
      totalCount: 0,
      tableData: [],
      loading: false,
      loadingView: null
    }
  },
  watch: {
    loading: {
      handler(value) {
        if (value) {
          this.startLoading('deviceShareStatus')
        } else {
          if (this.loadingView == null) {
            return
          }
          this.loadingView.close()
        }
      }
    }
  },
  mounted() {
    this.dataQuery = this.$route.query
    let userInfo = this.$store.getters.userInfo
    let tenantId = userInfo.tenantId
    let timeType = 1
    let timeStr = this.formatDayDate(new Date().getTime())
    let startTime = timeStr + ' 00:00:00'
    let endTime = timeStr + ' 23:59:59'
    this.query['timeType'] = timeType
    this.query['startTime'] = startTime
    this.query['endTime'] = endTime
    this.query['tenantIds'] = tenantId
    this.$nextTick(() => {

    })

  },
  methods: {

    updateList: function() {
      this.page = 1
      this.getData()
    },
    searchEvent: function(res) {
      for (const resKey in res) {
        this.query[resKey] = res[resKey]
      }
      this.updateList()
    },
    pushDetail: function(res) {
      this.$push('deviceShareDetail/detailDetail', {
        id: res[this.deviceIdKey],
        showFooter: 0
      })
    },
    currentChange: function(index) {
      this.page = index
      this.loadRecord = true
      this.getData()
    },
    getData() {
      let shareListForWeb = () => {
        return new Promise(resolve => {
          let query = {
            page: this.page,
            pageSize: this.pageSize,
            orderStatus: ''
          }

          for (const queryKey in this.query) {
            query[queryKey] = this.query[queryKey]
          }

          this.$api.deviceWorkManager.shareListForWeb(query).then(res => {
            let list = []
            if (this.$valueIsExist(res, 'data')) {
              list = res['data']
            }
            let totalCount = 0
            if (this.$valueIsExist(res, 'totalCount')) {
              totalCount = res['totalCount']
            }
            resolve({ result: true, list: list, totalCount: totalCount })
          }).catch(e => {
            resolve({ result: true, list: [], totalCount: 0 })
          })
        })
      }

      let shareOrderListByStatus = () => {
        return new Promise(resolve => {
          let orderStatus = ''
          if (this.$valueIsExist(this.dataQuery, 'orderStatus')) {
            orderStatus = this.dataQuery['orderStatus']
          }

          let shareType = ''
          if (this.$valueIsExist(this.dataQuery, 'shareType')) {
            shareType = this.dataQuery['shareType']
          }

          let query = {
            page: this.page,
            pageSize: this.pageSize,
            orderStatus: orderStatus,
            shareType: shareType
          }

          for (const queryKey in this.query) {
            query[queryKey] = this.query[queryKey]
          }

          this.$api.deviceOrg.shareOrderListByStatus(query).then(res => {
            let list = []
            if (this.$valueIsExist(res, 'data')) {
              list = res['data']
            }
            let totalCount = 0
            if (this.$valueIsExist(res, 'totalCount')) {
              totalCount = res['totalCount']
            }
            resolve({ result: true, list: list, totalCount: totalCount })
          }).catch(e => {
            resolve({ result: true, list: [], totalCount: 0 })
          })
        })
      }

      this.loading = true

      let itemType = ''
      if (this.$valueIsExist(this.dataQuery, 'itemType')) {
        itemType = this.dataQuery['itemType']
      }

      if (itemType == 'two') {
        this.keyList = [{ key: 'brandName', name: '品 牌', space: '21px' },
          { key: 'modelName', name: '型 号', space: '21px' },
          { key: 'categoryName', name: '设备分类', space: '0px' },
          { key: 'label', name: this.$VUEAPPDEVICECODE(), space: '0px' },
          { key: 'code', name: '卡 片 号', space: '3px' },
          { key: 'status', name: '共享状态', space: '3px' }
        ]
      }

      if (this.headerTitle == '在池') {
        this.deviceIdKey = 'deviceId'
        shareListForWeb().then(res => {
          this.tableData = res.list
          this.totalCount = res.totalCount
          this.loading = false
        })
      } else {
        this.deviceIdKey = 'deviceId'
        shareOrderListByStatus().then(res => {
          this.tableData = res.list
          this.totalCount = res.totalCount
          this.loading = false
        })
      }

    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.title {
  width: 45px;
  text-align: center;
  position: relative;
  float: left;
  top: 50%;
  transform: translate(0px, -50%);
  font-weight: bold;
}

.deviceShareStatus {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;

  .contentView {
    padding: 0px 10px 0px 10px;
    width: 100%;
    height: calc(100% - 75px);
    overflow: scroll;

    .contentMsg {
      width: 100%;
      height: 30px;
      position: relative;
      top: 50%;
      transform: translate(0px, -50%);
      font-size: 25px;
      color: $color2;
      text-align: center;
    }
  }
}

::-webkit-scrollbar {
  display: none;
}

</style>
