<template>
  <div class="statusItemV2" @click.stop="pushDetail">
    <div class="header">
      <div class="title" :style="{ width: titleWidth }">
        {{ getValue() }}
      </div>

      <el-rate
          disabled
          :disabled-void-color="'#7A86B6'"
          class="rate"
          v-model="3.7"
          :allow-half="true"
          text-color="#ff9900"
          score-template="{value}"
      >
      </el-rate>
      <slot name="headHandle"/>
      <div
          v-if="showCancelShare"
          @click.stop="cancelShareAction"
          class="action"
      >
        取消共享
      </div>
    </div>

    <div class="content">
      <sub-item
          @clickAction="pushDetail"
          v-for="item in keyList"
          :key="item.key"
          :bracket="false"
          :spacing="item.space"
          :item-info="getKeyValue(item)"
          :width="'50%'"
      ></sub-item>
    </div>
    <slot name="footHandle"/>
    <div v-if="showAction" class="actionView">
      <div class="agree" @click.stop="agreeAction">同意</div>
      <div class="disagree" @click.stop="disagreeAction">拒绝</div>
    </div>
    <div v-if="showShare" class="actionView">
      <div class="agree" @click.stop="shareAction">设置共享</div>
    </div>
  </div>
</template>

<script>
import SubItem from '@/views/shareAndDispatch/device/deviceList/subItem'
import { formatOrderStatus } from '@/assets/JS/utils.js'

export default {
  name: 'statusItemV2',
  components: { SubItem },
  computed: {
    titleWidth() {
      let width = this.titleWidthSpace
      if (this.showCancelShare) {
        width = 100 + width
      }
      return `calc(100% - ${160}px)`
    }
  },
  props: {
    titleWidthSpace() {
      defaults:90
    },
    keyList: {
      type: Array,
      default: () => {
        return [
          { key: 'brandName', name: '品 牌', space: '21px' },
          { key: 'modelName', name: '型 号', space: '21px' },
          { key: 'label', name: this.$VUEAPPDEVICECODE(), space: '0px' },
          { key: 'firstShareTime', name: '首次共享', space: '0px' },
          { key: 'code', name: '卡 片 号', space: '3px' },
          { key: 'status', name: '共享状态', space: '3px' },
          { key: 'categoryName', name: '设备分类', space: '0px' },
          { key: 'loanCount', name: '共享次数', space: '21px' }
        ]
      }
    },
    headerTitelKye: {
      type: String,
      default: 'deviceName'
    },
    showCancelShare: {
      type: Boolean,
      default: false
    },
    showShare: {
      type: Boolean,
      default: false
    },
    showAction: {
      type: Boolean,
      default: false
    },
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    shareAction: function() {
      this.$emit('shareAction', this.dataInfo)
    },
    cancelShareAction: function() {
      this.$emit('cancelShareAction', this.dataInfo)
    },
    disagreeAction: function() {
      this.$emit('disagree', this.dataInfo)
    },
    agreeAction: function() {
      this.$emit('agree', this.dataInfo)
    },
    pushDetail: function() {
      this.$emit('pushDetail', this.dataInfo)
    },
    getValue: function() {
      if (this.$valueIsExist(this.dataInfo, this.headerTitelKye)) {
        return this.dataInfo[this.headerTitelKye]
      }
      return '-'
    },
    getKeyValue: function(item) {
      let data = {
        title: item.name,
        value: '-',
        showDetail:false,
        detailValue:'',
      }

      if (this.$valueIsExist(this.dataInfo, item.key)) {
        let _value = this.dataInfo[item.key]
        if (_value != '' || _value != undefined || _value != null) {
          data.value = _value
        } else {
          data.value = '-'
        }
        if (item.key == 'firstShareTime') {
          data.value = this.formatTimeHHMM(data.value)
          data.showDetail = true;
          data.detailValue = this.formatDate(_value)
        } else if (item.key == 'orderTime') {
          data.value = this.formatTimeHHMM(data.value)
          data.showDetail = true;
          data.detailValue = this.formatDate(_value)
        } else if (item.key == 'orderStatus' || item.key == 'status') {
          let str = ''
          str = formatOrderStatus(data.value)
          data.value = str
        }
      }

      return data
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.statusItemV2 {
  overflow: hidden;
  border-radius: 10px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  background-color: white;
  box-shadow: 0px 0px 5px $color4;

  .header {
    width: 100%;
    background-color: $sub_color_primary;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .rate {
      margin-left: 5px;
      margin-right: 5px;
      width: 70px;
    }

    .title {
      font-weight: bold;
      margin-left: 10px;
      font-size: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .action {
      border-radius: 5px;
      margin-right: 10px;
      width: 70px;
      height: 25px;
      text-align: center;
      color: white;
      line-height: 25px;
      background-color: $color_primary;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 4px;
  }

  .actionView {
    width: 100%;
    height: 25px;
    margin-top: 4px;
    margin-bottom: 10px;


    .agree {
      border-radius: 5px;
      margin-right: 10px;
      float: right;
      width: 70px;
      height: 25px;
      text-align: center;
      color: white;
      line-height: 25px;
      background-color: $color_primary;
    }

    .disagree {
      border-radius: 5px;
      margin-right: 10px;
      float: right;
      width: 70px;
      height: 25px;
      text-align: center;
      color: white;
      line-height: 25px;
      background-color: orangered;
    }
  }
}

::v-deep .rate .el-rate__item {
  width: 12px;
}

::v-deep .rate .el-rate__icon {
  font-size: 12px;
}

::v-deep .rate .el-rate__text {
  margin-left: 3px;
}
</style>
