<template>
  <div class="statusItem">
    <div class="itemImgView">
      <svg-icon
        class="icon"
        width="15px"
        height="15px"
        name="arrow-right"
      ></svg-icon>
      <div class="iconContent">
        <div class="title">呼吸机</div>
        <div class="subTitle">IC-111</div>
        <div class="subTitle">万瑞</div>
        <el-rate
          class="rate"
          v-model="3.7"
          disabled
          show-score
          text-color="#ff9900"
          score-template="{value}"
        >
        </el-rate>
      </div>
    </div>
    <div class="itemContent">
      <sub-item
        v-for="item in keyList"
        :key="item.key"
        :bracket="false"
        :spacing="'3px'"
        :item-info="getKeyValue(item)"
        :width="'50%'"
      ></sub-item>
    </div>
    <svg-icon
      class="arrowIcon"
      width="20px"
      height="20px"
      name="arrow-right"
    ></svg-icon>
  </div>
</template>

<script>
import SubItem from "@/views/shareAndDispatch/device/deviceList/subItem";

export default {
  name: "statusItem",
  components: { SubItem },
  props: {
    keyList: {
      type: Array,
      default: () => {
        return [
          { key: "a", name: this.$VUEAPPDEVICECODE() },
          { key: "b", name: "共享时间" },
          { key: "c", name: "卡 片 号" },
          { key: "d", name: "共 享 人" },
          { key: "e", name: "设备分类" },
          { key: "f", name: "设备科室" },
        ];
      },
    },

    itemInfo: {
      type: Object,
      default: () => {
        return {
          a: "123",
          b: "2022-10-20",
          c: "456",
          d: "哈哈哈",
          e: "呼吸机",
          f: "中科",
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    getKeyValue: function (item) {
      let data = {
        title: item.name,
        value: "-",
      };

      if (this.$valueIsExist(this.itemInfo, item.key)) {
        data.value = this.itemInfo[item.key];
      }

      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.statusItem {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  border-bottom: 1px solid $border_color1;

  .itemImgView {
    float: left;
    width: 100px;
    height: 100%;

    .icon {
      float: left;
      width: 15px;
      height: 15px;
      position: relative;
      top: 50%;
      transform: translate(0%, -50%);
    }

    .iconContent {
      width: calc(100% - 15px);
      height: 100%;
      float: right;

      .title {
        width: 100%;
        height: 25px;
        line-height: 25px;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .subTitle {
        width: 100%;
        height: 25px;
        line-height: 25px;
        font-size: 14px;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .rate {
        width: 100%;
        height: 25px;
      }
    }
  }

  .itemContent {
    float: left;
    width: calc(100% - 130px);
    height: 90%;
    transform: translate(0px, 5.555%);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-left: 1px solid $border_color1;
  }

  .arrowIcon {
    float: right;
    width: 20px;
    height: 20px;
    position: relative;
    top: 50%;
    transform: translate(0%, -50%);
    margin-right: 5px;
  }
}

::v-deep .rate .el-rate__item {
  width: 12px;
}

::v-deep .rate .el-rate__icon {
  font-size: 10px;
}

::v-deep .rate .el-rate__text {
  margin-left: 3px;
}
</style>
